import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';

import { Item, Section } from 'ngx-time-scheduler';

import * as moment from 'moment-timezone';
import { TIMEZONE } from './app.config';

export interface SchedulerSection extends Section {
  metadata?: any;
}

export interface SchedulerItem extends Item {
  metadata?: any;
}

export class SchedulerEvents {
  ItemDropped: (item: SchedulerItem) => void;
  ItemClicked: (item: SchedulerItem) => void;
  ItemContextMenu: (item: SchedulerItem, event: MouseEvent) => void;
  SectionClickEvent: (section: Section) => void;
  SectionContextMenuEvent: (section: Section, event: MouseEvent) => void;
  PeriodChange: (start: moment.Moment, end: moment.Moment) => void;
}

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<ngx-spinner></ngx-spinner><router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    public iconSet: IconSetService
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
    moment.tz.setDefault(TIMEZONE);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}

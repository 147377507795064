import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private readonly apiService: ApiService
  ) { }

  async login(username: string, password: string) {
    const auth = await this.apiService.login(username, password);
    if (auth.token) localStorage.setItem('token', auth.token);
    return !!auth.token;
  }

  async logout() {
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

  async refreshToken() {
    const authData = await this.apiService.requestToken();
    if (authData.token) localStorage.setItem('token', authData.token);
    else this.logout()
  }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private bypassList = [
    `${environment.apiUrl}/extras/timer`
  ];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    if (this.bypassList.includes(req.url)) {
      return next.handle(req);
    }
    
    const token: string = localStorage.getItem('token');
    const hotel: string = localStorage.getItem('current_hotel') || '4';

    let request = this.setCurrentHotel(req, 'current_hotel', hotel);

    if (token) {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });
    }

    if (this.apiService.showLoader)
      this.spinner.show();

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if ((err.status === 401 || err.status === 403)) {
          this.router.navigateByUrl('/login');
        }
        this.spinner.hide();
        return throwError(err);
      }),
      finalize(() => {
        if (this.apiService.autohideLoader)
          this.spinner.hide();
      })
    );
  }

  private setCurrentHotel(req: HttpRequest<any>, name: string, value: string) {
    switch(req.method.toLowerCase()) {
      case 'post': case 'put':
        if (req.body instanceof FormData) {
          return req.clone({ body: req.body.append(name, value) })
        } else {
          const foo = {}; foo[name] = value;
          return req.clone({ body: {...req.body, ...foo} })
        }
      case 'get': default:
        return req.clone({
          params: req.params.set(name, value)
        });
    }
  }

}
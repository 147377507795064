import { NgModule } from '@angular/core';

import { OnlyNumber } from './only-number';
import { OutsideClick } from './outside-click';

@NgModule({
  declarations: [
    OnlyNumber,
    OutsideClick
  ],
  exports: [
    OnlyNumber,
    OutsideClick
  ]
})
export class DirectivesModule { }

import { Moment } from "moment"

export interface iEvent {
    action: string;
    value: any;
}

export interface iToken {
    token: string;
}

export interface iCategory {
    id?: number;
    name: string;
    hotel?: number;
    isChecked?: boolean;
}

export const emptyCategory: iCategory = {
    id: undefined,
    name: null,
    hotel: undefined
}

export interface iService {
    id?: number;
    name: string;
    icon: string;
    thumbnail: string;
    hotel?: number;
    isChecked?: boolean;
}

export const emptyService: iService = {
    id: undefined,
    name: null,
    icon: null,
    thumbnail: null,
    hotel: undefined,
}

export interface iFood {
    id?: number;
    name: string;
    description: string;
    type: string;
    price: number;
    iva: number;
    hotel?: number
    thumbnail: string;
}

export interface iFoodSelect {
    breakfast: iFood[];
    middle: iFood[];
    complete: iFood[];
}

export const emptyFood: iFood = {
    id: undefined,
    name: null,
    description: null,
    type: null,
    price: null,
    iva: null,
    thumbnail: null,
    hotel: undefined
}

export interface iExtra {
    id?: number;
    name: string;
    description: string;
    price: number;
    iva: number;
    hotel?: number;
    isChecked?: boolean;
    thumbnail: string;
    logo: string;
    logo_desc: string;
    perMinute: boolean;
    timer?: any;
}

export const emptyExtra: iExtra = {
    id: undefined,
    name: null,
    description: null,
    price: null,
    iva: null,
    thumbnail: null,
    logo: null,
    logo_desc: null,
    perMinute: false,
    hotel: undefined
}

export interface iExtraMat {
    id?: number;
    name: string;
    description: string;
    long_description: string;
    price: number;
    iva: number;
    quantity: number;
    hotel?: number;
    isChecked?: boolean;
    thumbnail: string;
    logo: string;
    logo_desc: string;
    logo_extra: string;
    per_day: boolean;
}

export const emptyExtraMat: iExtraMat = {
    id: undefined,
    name: null,
    description: null,
    long_description: null,
    price: null,
    iva: null,
    quantity: null,
    thumbnail: null,
    logo: null,
    logo_desc: null,
    logo_extra: null,
    per_day: false,
    hotel: undefined
}

export interface iExtraSynergy {
    id?: number;
    name: null,
    description: string;
    price: number;
    iva: number;
    hotel?: number;
    synergy_id: string;
    err?: string;
}

export const emptyExtraSynergy: iExtraSynergy = {
    id: undefined,
    name: null,
    description: null,
    price: null,
    iva: null,
    synergy_id: null,
    hotel: undefined,
    err: null
}

export interface iConsumable {
    id?: number;
    name: null,
    description: string;
    price: number;
    iva: number;
    quantity?: number;
    hotel?: number;
    thumbnail: string;
    fridge: boolean;
    err?: string;
}

export const emptyConsumable: iConsumable = {
    id: undefined,
    name: null,
    description: null,
    price: null,
    iva: null,
    quantity: null,
    thumbnail: null,
    fridge: false,
    hotel: undefined,
    err: null
}

export interface iFaIcon {
    icon: string;
}

export interface iRoom {
    id?: number;
    name: string;
    synergy_name: string;
    description: string;
    long_description: string;
    images?: string[];
    type?: string;
    price_type: string;
    price: string;
    iva: number;
    services: string | iService[];
    categories: string | iCategory[];
    guests_min: number;
    guests_max: number;
    numbers?: number[];
    in_web: boolean;
    hotel?: number;
}

export const emptyRoom: iRoom = {
    id: undefined,
    name: null,
    synergy_name: null,
    description: null,
    long_description: null,
    images: null,
    type: null,
    price_type: null,
    price: null,
    iva: null,
    services: null,
    categories: null,
    guests_min: null,
    guests_max: null,
    numbers: null,
    in_web: false,
    hotel: undefined
}

export interface iRoomNumberClean {
    skip_clean: boolean;
    clean_date: String;
    clean_hour: String;
    room_number?: number;
}

export interface iRoomNumberLock {
    room?: number;
    internalId?: string;
    room_number?: number;
    locked_date: String;
    locked_status: String;
}

export interface iRoomNumber {
    id?: number;
    number: string;
    room: number | iRoom;
    hotel?: number;
    isBooked?: boolean;
    doorId?: string;
    jacuzziId?: string;
    boxId?: string;
    internalId?: string;
    clean?: iRoomNumberClean;
    lock?: iRoomNumberLock
}

export const emptyRoomNumber: iRoomNumber = {
    id: undefined,
    number: null,
    room: null,
    hotel: undefined,
    doorId: null
}

export interface iGuest {
    id?: number;
    firstname: string;
    lastname: string;
    doc_type: string;
    doc_number: string;
    genre: string;
    email: string;
    phone: string;
    country: string;
    address: string;
    verified?: boolean;
    verifycode?: string;
    avatar_file?: string;
    rating?: number;
    points?: number;
    password?: string;
    documents?: iGuestDocument[];
}

export const emptyGuest: iGuest = {
    id: undefined,
    firstname: null,
    lastname: null,
    doc_type: null,
    doc_number: null,
    genre: null,
    email: null,
    phone: null,
    country: null,
    address: null,
    verified: false,
    verifycode: null,
    avatar_file: null,
    rating: null,
    points: null,
    password: null,
    documents: []
}

export interface iGuestDocument {
    id?: number;
    guest: number;
    name: string;
    document: string;
}

export const emptyGuestDocument: iGuestDocument = {
    id: undefined,
    guest: null,
    name: "",
    document: ""
}

export interface iBooking {
    id?: undefined;
    room: any;
    room_hour?: string;
    guest: any;
    extra_guests?: any;
    number?: any;
    user?: any;
    pillow_a?: any;
    pillow_b?: any;
    mattress?: any;
    booking_type: string;
    booking_number?: string;
    food?: any;
    guest_type: string;
    reserve_from: string;
    reserve_to: string;
    checkin_date?: string;
    checkout_date?: string;
    guests_numbers: number;
    extras?: number[] | string | iExtra[];
    extras_synergy?: number[] | string | iExtraSynergy[];
    consumables?: number[] | string | iConsumable[];
    lostobjects?: number[] | string | iBookingLostObject[];
    dropped?: boolean;
    reserve_from_as_date?: Date;
    reserve_to_as_date?: Date;
    comments?: string;
    comments_cleanning?: string;
    comments_synergy?: string;
    cleaned?: boolean;
    precheck_number?: any;
    last_rate?: number;
    email_checkin?: boolean;
    email_checkout?: boolean;
    room_revenue?: any;
    number_revenue?: any;
    precheck_number_revenue?: any;
}

export const emptyBooking: iBooking = {
    id: undefined,
    room: null,
    guest: null,
    number: null,
    user: null,
    booking_type: null,
    guest_type: null,
    reserve_from: null,
    reserve_to: null,
    checkin_date: null,
    checkout_date: null,
    guests_numbers: null,
    extra_guests: [],
    extras: null,
    dropped: false
}

export interface iBookingExtraMat {
    id?: number;
    extra_mat: any;
    reserve_from: string;
    reserve_to: string;
    dropped: boolean;
    booking: number;
}

export interface iBookingLostObject {
    id?: number;
    description: string;
    image: string;
    found_date: string,
    sentagency_date: string,
    pickuphotel_date: string,
    bookings: number;
}

export const emptyBookingLostObject: iBookingLostObject = {
    id: undefined,
    description: null,
    image: null,
    found_date: null,
    sentagency_date: null,
    pickuphotel_date: null,
    bookings: null
}

export const emptyBookingExtraMat: iBookingExtraMat = {
    id: undefined,
    extra_mat: null,
    reserve_from: null,
    reserve_to: null,
    dropped: null,
    booking: null
}

export interface iBookingSynergy {
    internalID: number;
    externalID: number;
    guestInfo: {
        email: string;
        name: string;
        phone: string;
    },
    roomInfo: iBookingSynergyRoom[],
    selected: boolean;
}

interface iBookingSynergyRoom {
    total: number;
    from: string;
    to: string;
    data: any;
}

export interface iBilling {
    id?: number;
    total: number;
    bookedroom: iBooking;
    extras: iBillingExtra[];
    extras_synergy: iBillingExtraSynergy[];
    consumables: iBillingConsumable[];
    promos?: any;
    user: iUser;
    rate?: iRate;
    company?: iCompany;
    payments?: iPayment[];
    restaurant?: any;
    year: number;
    internal_id: string;
    dropped_id?: number;
    internal_dropped_id?: number;
    hotel?: number;
    download_bill?: string;
    download_refund?: string;
}

export const emptyBilling: iBilling = {
    id: undefined,
    total: null,
    bookedroom: null,
    extras: null,
    extras_synergy: null,
    consumables: null,
    user: null,
    rate: null,
    payments: [],
    year: null,
    internal_id: null
}

export interface iBillingExtra {
    id?: number;
    billing: number;
    description: string;
    from: string;
    to: string;
    price: number;
    quantity: number;
    per_minute: boolean;
    is_mat: boolean;
    iva: number;
    timer?: any;
}

export const emptyBillingExtra: iBillingExtra = {
    id: undefined,
    billing: null,
    description: null,
    from: null,
    to: null,
    price: null,
    quantity: null,
    per_minute: false,
    is_mat: false,
    iva: null
}

export interface iBillingExtraSynergy {
    id?: number;
    bookings: string;
    extras_synergy: iExtraSynergy;
    quantity: number;
}

export const emptyBillingExtraSynergy: iBillingExtraSynergy = {
    id: undefined,
    bookings: null,
    extras_synergy: null,
    quantity: null
}

export interface iBillingConsumable {
    id?: number;
    bookings: string;
    consumables: iConsumable;
    quantity: number;
}

export const emptyBillingConsumable: iBillingConsumable = {
    id: undefined,
    bookings: null,
    consumables: null,
    quantity: null
}

export interface iRole {
    id: number;
    name: string;
    tag: string;
    isChecked?: boolean;
}

export const emptyRole: iRole = {
    id: undefined,
    name: null,
    tag: null
}

export interface iUser {
    id?: number;
    fullname: string;
    email: string;
    password?: string;
    notes: string;
    roles: string | iRole[];
    hotels: string | iHotel[];
}

export const emptyUser: iUser = {
    id: undefined,
    fullname: null,
    email: null,
    notes: null,
    password: null,
    roles: null,
    hotels: null
}

export interface iHotel {
    id?: number;
    name: string;
    address: string;
    address_template: string;
    synergy: string;
    email: string;
    phone: string;
    mobile: string;
    city: string;
    postcode: string;
    zone: number;
    templates?: boolean;
    enabled?: boolean;
    isChecked?: boolean;
    web?: string;
}

export const emptyHotel: iHotel = {
    id: undefined,
    name: null,
    address: null,
    address_template: null,
    synergy: null,
    zone: null,
    email: null,
    phone: null,
    mobile: null,
    city: null,
    postcode: null,
    templates: false,
    enabled: false,
    isChecked: false
}

export interface iZone {
    id?: number;
    name: string;
    alias: string;
}

export const emptyZone: iZone = {
    id: undefined,
    name: null,
    alias: null
}

export interface iMillaveDoor {
    uuid: string;
    alias: string;
    room: string;
}

export const iMillaveDoor = {
    uuid: null,
    alias: null,
    room: null
}

export interface iPromoCode {
    id?: number;
    name: string;
    code: string;
    type: string;
    value: number;
    hotel?: number;
    enabled?: boolean;
}

export const emptyCode: iPromoCode = {
    id: undefined,
    name: null,
    code: null,
    type: null,
    value: null,
    hotel: null,
    enabled: false
}

export interface iRate {
    id?: number;
    room: number;
    type: string,
    value: number;
    date_from: string;
    date_to: string;
    hotel?: number;
    dropped?: boolean;
}

export const emptyRate: iRate = {
    id: undefined,
    room: null,
    type: null,
    value: null,
    date_from: null,
    date_to: null
}

export interface iCurrentExtraMat {
  id: number;
  startDate: Moment;
  endDate: Moment;
  err?: string;
}

export interface iCountry {
    id?: number;
    name: string;
    isocode: string;
    phonecode: string;
}

export const emptyCountry: iCountry = {
    id: undefined,
    name: null,
    isocode: null,
    phonecode: null,
}

export interface iCompany {
    id?: number;
    name: string;
    social: string;
    document: string;
    email: string;
    phone: string;
    address: string;
    country?: number | iCountry;
    dropped?: boolean;
}

export const emptyCompany: iCompany = {
    id: undefined,
    name: null,
    social: null,
    document: null,
    email: null,
    phone: null,
    address: null,
    country: null
}

export interface iPayment {
    id?: number;
    type: string;
    reference: string;
    concept: string;
    amount: number;
    refund: boolean;
    payment_date: string;
    payment_reference: string;
    billing: any;
}

export const emptyPayment: iPayment = {
    id: undefined,
    type: null,
    reference: null,
    concept: null,
    amount: null,
    refund: false,
    payment_date: null,
    payment_reference: null,
    billing: null
}

export interface iMattress {
    id?: number;
    name: string;
    firmness: string;
    image: string;
    min_imc: number;
    max_imc: number;
    hotel?: number;
}

export const emptyMattress: iMattress = {
    id: undefined,
    name: null,
    firmness: null,
    image: null,
    min_imc: null,
    max_imc: null,
    hotel: undefined
}

export interface iPillow {
    id?: number;
    name: string;
    description: string;
    image: string;
    image_desc: string;
    hotel?: number;
}

export const emptyPillow: iPillow = {
    id: undefined,
    name: null,
    description: null,
    image: null,
    image_desc: null,
    hotel: undefined
}

export interface iCard {
    id?: number;
    holder: string;
    number: string;
    month: number;
    year: number;
    cvv: number;
}

export const emptyCard: iCard = {
    id: undefined,
    holder: null,
    number: null,
    month: null,
    year: null,
    cvv: null
}

export interface calendarData {
  type: string;
  name: string;
  id: number;
  number: number;
}

export interface iTask {
    id?: number;
    name: string;
    type: string;
    custom: string;
    enabled?: boolean;
    hotel?: number;
    records?: iTaskRecords[];
}

export interface iTaskRecords {
    id?: number;
    task: number;
    check_at: string;
}

export const emptyTask: iTask = {
    id: undefined,
    name: null,
    type: null,
    custom: null,
    enabled: false,
    hotel: undefined,
    records: []
}

export interface iMaintenance {
    id?: number;
    name: string;
    priority: string;
    enabled?: boolean;
    hotel?: number;
    records?: iMaintenanceRecords[];
}

export interface iMaintenanceRecords {
    id?: number;
    maintenance: number;
    check_at: string;
    comment: string;
}

export const emptyMaintenance: iMaintenance = {
    id: undefined,
    name: null,
    priority: null,
    enabled: false,
    hotel: undefined,
    records: []
}

export interface iQuestion {
    id?: number;
    name: string;
    enabled?: boolean;
    hotel?: number;
    records?: iQuestionRecords[];
}

export interface iQuestionRecords {
    id?: number;
    question: number;
    bookedroom: number;
    response: string;
}

export const emptyQuestion: iQuestion = {
    id: undefined,
    name: null,
    enabled: false,
    hotel: undefined,
    records: []
}

export interface iQuestionsBooking {
    id: number;
    name: string;
    response: {
        id: number;
        value: string;
    }
}

interface iLimitsCount {
    app: number;
    web: number;
}

export interface iLimits {
    id?: number;
    datetime: string;
    app: number;
    web: number; 
    count?: iLimitsCount;
}

export const emptyLimits: iLimits = {
    id: undefined,
    datetime: null,
    app: 0,
    web: 0
}

export interface iDateSelection {
    from: number;
    to: number;
}

export interface iResponse {
    status: string;
}

export interface iTutorials {
    id?: number;
    name: string;
    description: string;
    long_description: string;
    hotel?: number;
    enabled: boolean;
    thumbnail: string;
    source: string;
}

export const emptyTutorials: iTutorials = {
    id: undefined,
    name: null,
    description: null,
    long_description: null,
    thumbnail: null,
    source: null,
    enabled: false,
    hotel: undefined
}

export interface iPromotions {
    id?: number;
    name: string;
    hotel?: number;
    promotion_from?: string;
    promotion_to?: string;
    promotion_type: string;
    enabled: boolean;
    image: string;
    destination?: string;
}

export const emptyPromotions: iPromotions = {
    id: undefined,
    name: null,
    promotion_from: null,
    promotion_to: null,
    promotion_type: null,
    image: null,
    destination: null,
    enabled: false,
    hotel: undefined
}

export interface iRestaurantOpeningHourDays {
    name?: string;
    hours: iRestaurantOpeningHours[]
}

export interface iRestaurantOpeningHours {
    id?: number;
    dayweek?: number;
    open?: number;
    close?: number;
    restaurant?: number | iRestaurant;
}

export const emptyRestaurantOpeningHours: iRestaurantOpeningHours = {
    id: undefined,
    dayweek: null,
    open: null,
    close: null,
}

export interface iRestaurantHotels {
    hotel?: number;
    restaurant?: number | iRestaurant;
    enabled: boolean;
}

export const emptyRestaurantHotels: iRestaurantHotels = {
    hotel: undefined,
    restaurant: null,
    enabled: false
}

export interface iRestaurant {
    id?: number;
    hotels?: iRestaurantHotels[] | string;
    opening_hours?: iRestaurantOpeningHours[];
    name: string;
    description: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    postcode: string;
    thumbnail: string;
    profile?: number;
}

export const emptyRestaurant: iRestaurant = {
    id: undefined,
    name: null,
    description: null,
    email: null,
    phone: null,
    address: null,
    city: null,
    postcode: null,
    thumbnail: null
}

export interface iRestaurantProfile {
    id?: number;
    name: string;
}

export interface iRestaurantFamily {
    id?: number;
    name: string;
    description: string;
    restaurant?: number | iRestaurant;
}

export const emptyRestaurantFamily: iRestaurantFamily = {
    id: undefined,
    name: null,
    description: null,
}

export interface iRestaurantProduct {
    id?: number;
    name: string;
    description: string;
    family?: number | iRestaurantFamily;
    price: number;
    tax: number;
    cost: number;
    enabled: boolean,
    restaurant?: number | iRestaurant;
    variants?: string | iRestaurantProductVariant[]
}

export const emptyRestaurantProduct: iRestaurantProduct = {
    id: undefined,
    name: null,
    description: null,
    price: 0,
    tax: 0,
    cost: 0,
    enabled: false
}

export interface iRestaurantProductVariant {
    id?: number;
    name: string;
    description: string;
    price: number;
    suppl: boolean,
    product?: number | iRestaurantProduct;
}

export const emptyRestaurantProductVariant: iRestaurantProductVariant = {
    id: undefined,
    name: null,
    description: null,
    price: 0,
    suppl: false
}

export interface iCivitatis {
    data?: iCivitatisDestinations[];
}

export interface iCivitatisDestinations {
    id?: number;
    title: string;
}

export const emptyCivitatisDestinations: iCivitatisDestinations = {
    id: undefined,
    title: null,
}

export interface iCalendar {
    id?: number;
    value: number;
    day: number;
    month: number;
    year: number;
    hotel?: number;
}

export interface iCalendarFilter {
    day?: string;
    month?: string;
    year?: string;
}

export const emptyCalendar: iCalendar = {
    id: null,
    value: null,
    day: null,
    month: null,
    year: null,
    hotel: null
}

export interface iLostObjectShipments {
    id?: number;
    lostobjects?: iBookingLostObject[];
    pickedup: boolean;
    shipping_name: string;
    shipping_phone: string;
    shipping_address: string;
    shipping_postcode: string;
    shipping_country: string;
    shipping_province: string;
    hotel?: number;
}

export const emptyLostObjectShipments: iLostObjectShipments = {
    id: undefined,
    pickedup: false,
    shipping_name: null,
    shipping_phone: null,
    shipping_address: null,
    shipping_postcode: null,
    shipping_country: null,
    shipping_province: null,
    hotel: null
}
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { emptyHotel, iHotel } from 'src/app/app.interfaces';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  sidebarMinimized = false;
  navItems = [];
  hotelList: iHotel[] = [];
  currentHotel: iHotel = emptyHotel;
  sidebarSize: String = "lg";
  searchCriteria: string = "";
  searchInvertal: any = null;
  isSearching: boolean = true;
  showProgressBar: boolean = false;
  reserveList: any = [];
  errorMessage: string = "";

  @ViewChild('logoutModal') logoutModal: ModalDirective;
  @ViewChild('errorModal') public errorModal: ModalDirective;
  @ViewChild('requestPasswordModal') public requestPasswordModal: ModalDirective;
  
  requestPasswordValue: string;
  requestPasswordAction: string;
  requestPasswordMetadata: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    public utilService: UtilService
  ) {
    this.apiService.apiEvents.subscribe(data => {
      if (!!data && data.action) {
        switch (data.action) {
          case 'hotel':
            this.loadHotels();
            break;
        
          case 'showProgressBar':
            setTimeout(() => this.showProgressBar = !!data.value, 500);
            break;
        }
      }
    });
    this.utilService.showModalEvent.subscribe(event => {
      if (!!event && event.action) {
        switch (event.action) {
          case 'error-modal':
            this.errorMessage = event.value;
            this.errorModal.show();
            break;
          case 'password-modal':
            this.requestPasswordValue = "";
            this.requestPasswordAction = event.value.action;
            this.requestPasswordMetadata = event.value.metadata;
            this.requestPasswordModal.show();
            break;
        }
      }
    });
  }

  async validatePassword () {
    const currentHotel = parseInt(localStorage.getItem('current_hotel'));
    const response = await this.apiService.valdiateHotelPassword(currentHotel, this.requestPasswordValue);
    if (response.status === 'ok') {
      this.requestPasswordModal.hide();
      this.utilService.showModalEvent.next({
        action: 'validate-password-approved',
        value: {
          action: this.requestPasswordAction,
          metadata: this.requestPasswordMetadata
        }
      });
    } else {
      this.errorMessage = `Contraseña incorrecta`;
      this.errorModal.show();
    }
  }

  async cancelPasswordValidation () {
    this.utilService.showModalEvent.next({
      action: 'validate-password-cancel',
      value: {
        action: this.requestPasswordAction,
        metadata: this.requestPasswordMetadata
      }
    });
    this.requestPasswordModal.hide()
  }

  async ngOnInit () {
    const roles = await this.apiService.getUserRoles();
    const availables = roles.map(r => r.tag);
    this.navItems = navItems.filter(r => r.hasOwnProperty("attributes") ? availables.includes(r.attributes['role']) : true)
    this.loadHotels();
  }

  private async loadHotels () {
    this.hotelList = await this.apiService.getUserHotels();
    const currentHotelId = localStorage.getItem('current_hotel') || '1';
    const firstAvailable = this.hotelList.filter(h => h.id == parseInt(currentHotelId))[0];
    if (firstAvailable) {
      this.currentHotel = Object.assign({}, firstAvailable);
    } else {
      this.authService.logout();
    }
  }

  changeHotel (hotel: iHotel) {
    this.currentHotel = hotel;
    localStorage.setItem('current_hotel', `${this.currentHotel.id}`);
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/dashboard']);
    });
  }

  toggleMinimize (e) {
    this.sidebarMinimized = e;
  }

  logout () {
    this.authService.logout();
  }

  viewDetails (id: string, type: string) {
    this.router.navigate([`/billing/${type}`, id]);
    this.searchCriteria = "";
    this.reserveList.length = 0;
  }

  prepareSearch (value) {
    if (value == "") {
      this.isSearching = true;
      this.searchCriteria = "";
      this.reserveList.length = 0;
      clearTimeout(this.searchInvertal);
    } else {
      this.searchCriteria = value;
      if (this.searchInvertal) { clearTimeout(this.searchInvertal); }
      this.searchInvertal = setTimeout(() => this.search(), 1000);
    }
  }

  private async search () {
    this.isSearching = true;
    this.showProgressBar = true;
    this.reserveList.length = 0;
    this.apiService.showLoader = false;
    this.reserveList = await this.apiService.getSearchedReserves(this.searchCriteria);
    this.apiService.showLoader = true;
    this.showProgressBar = false;
    this.isSearching = false;
  }
}

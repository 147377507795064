import { Injectable } from '@angular/core';
import { iBilling, iEvent, iGuest } from '../app.interfaces';
import { TIMEZONE } from 'src/app/app.config';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs/internal/Subject';
moment.tz.setDefault(TIMEZONE);

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public showModalEvent = new Subject<iEvent>();

  public localePicker = {
    applyLabel: 'Aceptar',
    format: 'DD/MM/YYYY'
  };

  public localePickerTimer = {
    applyLabel: 'Aceptar',
    format: 'DD/MM/YYYY HH:mm:ss'
  };

  public translation = {
    NextButton: 'Siguiente',
    PrevButton: 'Anterior',
    TodayButton: 'Hoy',
    GotoButton: 'Ir a Fecha',
    SectionTitle: ''
  };

  public bookingTime = {
    start: 14,
    end: 12
  };

  private formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'narrowSymbol'
  });

  showErrorModal (message: string) {
    this.showModalEvent.next({ action: 'error-modal', value: message });
  }

  validatePasswordModal (action: string, metadata: any) {
    this.showModalEvent.next({ action: 'password-modal', value: { action, metadata } });
  }

  pad (n, d?) {
    n = n + '';
    return n.length >= (d || 8) ? n : new Array(d || 8 - n.length + 1).join('0') + n;
  }

  round (num: any) {
    return !!num ? num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : 0;
  }

  strToDate (timestamp: string) {
    return new Date(parseInt(timestamp));
  }

  formatDate (timestamp: string, time?: boolean, dash?: boolean) {
    return timestamp ? moment(new Date(parseInt(timestamp))).format(`${dash ? 'YYYY-MM-DD' : 'DD/MM/YYYY'}${time ? ' HH:mm' : ''}`) : "-";
  }

  formatDateString (date: string) {
    return date ? date.replace("T", " ") : '-';
  }

  formatDateShort (timestamp: string) {
    return moment(new Date(parseInt(timestamp))).format('ll');
  }

  formatCurrency (value) {
    return this.formatter.format(value);
  }

  // TO-DO: combine both fnc (bookingType + bookingOptions)
  bookingOptions () {
    return [
      { value: 'admin', text: 'Hotel' },
      { value: 'app', text: 'App' },
      { value: 'web', text: 'Website' },
      { value: 'booking', text: 'Booking' },
      { value: 'callcenter', text: 'Call-Center' },
      { value: 'touroperador', text: 'Tour Operador' },
      { value: 'tourabonado', text: 'Tour Operador ya abonado' },
      { value: 'extended', text: 'Alarga Estancia' },
      { value: 'expedia', text: 'Expedia' }
    ]
  }

  bookingType (type: string) {
    switch (type) {
      case 'admin': default: return 'Hotel';
      case 'app': return 'App';
      case 'web': return 'Website';
      case 'booking': return 'Booking';
      case 'callcenter': return 'Call-Center';
      case 'touroperador': return 'Tour Operador';
      case 'tourabonado': return 'Tour Operador ya abonado';
      case 'extended': return 'Alarga Estancia';
      case 'expedia': return 'Expedia';
    }
  }

  paymentType (type: string) {
    switch (type) {
      case 'cash': default: return 'Efectivo';
      case 'card': return 'Tarjeta';
      case 'check': return 'Devolución';
      case 'transfer': return 'Datafono';
      case 'deposit': return 'Pagado en Booking';
      case 'banktransfer': return 'Pago Transferencia';
      case 'keytel': return 'TourOperador';
      case 'expedia': return 'Pagado Expedia';
    }
  }

  formatGuestData (guest: iGuest, type: string) {
    switch (type) {
      case 'fullname': return `${guest.firstname} ${guest.lastname}`;
      case 'document': return `${guest.doc_type}: ${guest.doc_number}`;
      default: return "-";
    }
  }

  formatBillNumber (billing: iBilling) {
    if (billing.year > 2022) {
      return !!billing.internal_id ? `${this.pad(billing.hotel, 4)}/${billing.internal_id}` : '-';
    } else {
      return this.pad(billing.id);
    }
  }

  formatBillNumberDropped (billing: iBilling) {
    return `${this.pad(billing.hotel, 4)}/${this.pad(billing.internal_dropped_id)}`
  }

  setBookingTime (momentDate, value: string) {
    switch (value) {
      case 'start': default: return momentDate.startOf('day').set({ hour: this.bookingTime.start, minute: 0, millisecond: 0 });
      case 'end': return momentDate.endOf('day').set({ hour: this.bookingTime.end, minute: 0, millisecond: 0 });
    }
  }

  randomString (length?: number) {
    const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
    let string = '';
    for (let i = 0; i < (length || 20); i++) {
      string += chars[Math.floor(Math.random() * chars.length)];
    }
    return string;
  }

  downloadBase64Image (base64: string, filename: string, contentType?: string) {
    const navigator: any = window.navigator;
    const blobData = this.convertBase64ToBlobData(base64);

    if (navigator && navigator.msSaveOrOpenBlob) { //IE
      navigator.msSaveOrOpenBlob(blobData, filename);
    } else {
      const blob = new Blob([blobData], { type: contentType || blobData.type || 'image/png' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.download = filename;
      link.click();
    }
  }

  private convertBase64ToBlobData (base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent {

  loginError: boolean;
  mainForm: FormGroup;

  constructor(
    private router: Router,
    public authService: AuthService,
    private apiService: ApiService
  ) {
    this.loginError = false;
    const autoFill = environment.apiUrl.includes('localhost');
    this.mainForm = new FormGroup({
      email: new FormControl(autoFill ? 'admin@admin.com' : ''),
      password: new FormControl(autoFill ? 'adminadmin' : ''),
    });
  }

  async onSubmit() {
    this.loginError = false;
    const data = await this.authService.login(
      this.mainForm.value.email,
      this.mainForm.value.password
    );
    if (data) {
      let changeDefaultHotel = true;
      const currentHotel = localStorage.getItem('current_hotel');
      const hotelList = await this.apiService.getUserHotels()
      for (let i = 0; i < hotelList.length; i++) {
        if (hotelList[i].id == parseInt(currentHotel)) {
          changeDefaultHotel = false;
          break;
        }
      }
      if (changeDefaultHotel) {
        localStorage.setItem('current_hotel', `${hotelList[0].id}`);
      } this.router.navigateByUrl('/dashboard');
    } else {
      this.loginError = true;
    }
  }

}
